*, ::after, ::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body{
  position: relative;
  height: 100vh;
}

#root{
  height: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.background {
  background-color: rgb(91, 69, 214);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container {
  overflow: hidden;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  background-color: rgba(255, 255, 255, 0.527);
  backdrop-filter: blur(150px);
  width: 50%;
  min-width: 300px;
  max-width: 500px;
}

.container__picture{
  border-radius: 1em;
  overflow: hidden;
}

.container__picture picture,
.container__picture img{
  width: 100%
}

.container__label .label__first{
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bolder;
  color: rgb(84, 84, 156);
}

.container__label .label__copy{
  text-align: center;
  font-size: .8rem;
  color: rgb(89, 89, 112);
}